import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/brand'


export const getBrands = (obj = { pageIndex: 0, pageSize: 10 }, search = '') => {
    return axios.get(`${url}/`)
}

export const deleteBrandById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateBrandById = (formData, id) => {
    return axios.patch(`${url}/updateById/${id}`, formData);
};


export const addBrand = (formData) => {
    return axios.post(`${url}/`, formData);
};

export const getById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}