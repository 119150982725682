import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { errorToast, successToast } from '../../../utils/toast.util';
import { deleteProductById, getAllProducts } from '../../../services/product.service';
import { getAllRequests } from '../../../services/request.service';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Download, Replay } from '@mui/icons-material';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import CustomTable from '../../../components/CustomTable';
import { REQUEST_TYPE } from '../../../utils/constants';

export default function ViewRequest() {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const [requestType, setRequestType] = useState(REQUEST_TYPE.BUY)

  // const [searchStr, setSearchStr] = useState('')


  const { isLoading, isFetching, error, data, refetch } = useQuery({
    queryKey: ['requests', pagination, requestType],
    queryFn: () => getAllRequests(pagination, { requestType }).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })


  const loading = isLoading || isFetching;

  const columns = useMemo(() => [
    {
      header: "Id",
      accessorFn: row => row.id,
      // cell: info => info.getValue(),
    },
    // {
    //   header: "Description",
    //   accessorFn: row => row.description,
    // },
    {
      header: "User",
      accessorFn: row => row.userId?.name,
    },
    {
      header: "View",
      cell: info => <Button variant='outlined' onClick={() => navigate(`/admin/request/bid/${info.row?.original?._id}`)} >View</Button>,
    },
  ], [navigate])


  return (
    <>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden', }} >

        <CustomTable
          label={'View Requests'}
          columns={columns}
          data={data?.data}
          totalCount={data?.count}
          loading={loading}
          onChangePagination={setPagination}
          pagination={pagination}
          reload={refetch}
          serverPagination
          labelExtraComponent={
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: 1 }}>
                </Box>
                <Box>

                  <FormControl >
                    <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={requestType}
                      label="Request Type"
                      onChange={(e) => setRequestType(e.target.value)}
                    >
                      <MenuItem value={REQUEST_TYPE.BUY}>Buy</MenuItem>
                      <MenuItem value={REQUEST_TYPE.SELL}>Sell</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

              </Box>
            </>
          }
        // searchExtraComponent={
        //   <>
        //     asdfas
        //     <IconButton>
        //       <Download></Download>
        //     </IconButton>
        //   </>
        // }
        // searchStr={searchStr}
        // setSearchStr={setSearchStr}
        />
      </Paper >
    </>

  )
}
