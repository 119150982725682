import React from 'react';
import {
    Navigate, Route, Routes
} from "react-router-dom";
import Support from './Support';
import SupportDetails from './SupportDetails';

export default function SupportRouter() {
    return (
        <Routes>
            <Route path="" element={<Support />} />
            <Route path="details/:id" element={<SupportDetails />} />
            <Route path="*" element={<Navigate to="../" relative="route" />} />
        </Routes>
    )
}
