import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

const initialState = {
    isAuthorized: false,
    user: null,
    role: null,
    token: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, { payload }) => {
            state.token = payload
            state.isAuthorized = true
            let tokenData = jwtDecode(payload)
            state.user = tokenData
            state.role = tokenData.role
        },
        resetAuth: () => {
            return initialState
        },
    },
})

// Action creators are generated for each case reducer function
export const { setToken, resetAuth } = authSlice.actions

export default authSlice.reducer
