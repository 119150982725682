import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom';
import { ROLES } from '../utils/constants';
import { useSelector } from 'react-redux';

export const useLoginRedirectPath = () => {
    const role = useSelector(state => state.auth.role)
    const user = useSelector(state => state.auth.user)
    const path = useMemo(() => {
        if (role == ROLES.ADMIN) {
            return '/admin/user/view'
        }
        else if (role == ROLES.SUBADMIN) {
            let accessObj = user.user.accessObj
            if (accessObj.manageUsers) {
                return '/admin/user/view'
            }
            if (accessObj.manageCategory) {
                return '/admin/category/view'
            }
        }
        

    }, [role, user])
    return path


}

export default function ProtectedRoute({ children, isAllowed, redirectPath }) {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }
    return children
}
