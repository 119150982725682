import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';
import { login } from '../services/user.service';
import { setJwt } from '../utils/jwt.util';
import { getRedirectUrl } from '../utils/redirect.util';
import { errorToast, successToast } from '../utils/toast.util';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/features/user/authSlice';

export default function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async () => {
    try {
      let formData = {
        email,
        password
      }
      const res = await login(formData)
      if (res.data.token) {
        setJwt(res.data.token)
        dispatch(setToken(res.data.token))
      }
      const url = getRedirectUrl()
      console.log("URL", url)
      navigate(url)

      successToast(res.data.message)
    }
    catch (err) {
      errorToast(err)
    }
  }
  return (
    <Box sx={{ minHeight: '100vh', minWidth: '100vw', display: 'grid', placeItems: 'center', backgroundColor: '#115' }} component={'form'} onSubmit={(e) => { e?.preventDefault(); handleSubmit(); }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: 'min(400px, 45vh)' }} >
        <Typography variant='h4'>Login</Typography>
        <TextField label="Email" value={email} name='email' onChange={e => setEmail(e.target.value)} sx={{ my: 1 }} />
        <TextField label="Password" type='password' name='password' value={password} onChange={e => setPassword(e.target.value)} sx={{ my: 1 }} />
        <Button variant='contained' type='submit'>Submit</Button>
      </Paper>
    </Box>
  )
}
