import { Box, Button, IconButton, Paper, TextField, Tooltip, Typography, styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getChatsBySupportTicketId, getSupportById, sendChat } from '../../../services/support.service'
import { errorToast, successToast } from '../../../utils/toast.util'
import { getPurchaseOrderById } from '../../../services/purchaseOrder.service'
import { format } from 'date-fns'
import { ROLES } from '../../../utils/constants'
import { Refresh } from '@mui/icons-material'

export default function SupportDetails() {

  const params = useParams()

  const { data } = useQuery({
    queryKey: ['support_ticket', params?.id],
    queryFn: () => getSupportById(params?.id).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })

  const { data: orderData } = useQuery({
    queryKey: ['orderSupport', data?.data?.purchaseOrderId],
    queryFn: () => getPurchaseOrderById(data?.data?.purchaseOrderId).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })

  const { isLoading, isFetching, error, data: chatData, refetch } = useQuery({
    queryKey: ['supportChat', params?.id],
    queryFn: () => getChatsBySupportTicketId(params?.id).then(res => res?.data.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })

  const containerRef = useRef()
  useEffect(() => {
    console.log(containerRef.current.scrollHeight, "commentsContainerRef.current.clientHeight")
    let scrollHeightValue = containerRef.current.scrollHeight
    containerRef.current.scrollTop = scrollHeightValue;
  }, [chatData])


  const loading = isLoading || isFetching;




  const [message, setMessage] = useState('')


  const sendMessage = async () => {
    try {
      if (message.trim()) {
        let obj = {
          message: message.trim(),
          supportTicketId: params?.id,
          purchaseOrderId: data?.data?.purchaseOrderId,
        }
        const res = await sendChat(obj)
        if (res.data?.message) {
          successToast(res.data?.message)
        }

        setMessage('')
        refetch()

      }
    } catch (error) {
      errorToast(error)
    }

  }


  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden', p: 5 }} >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>

          <Box sx={{ flex: 1 }}>

            <Box>
              <Typography variant='h5'>Issue : {data?.data?.issue}</Typography>
              <Typography >Details : {data?.data?.issueDetails}</Typography>
            </Box>
            <Box sx={{ marginTop: 2, }}>
              <Typography variant='h6' >User Details:</Typography>
              <Typography> Name : {data?.data?.userObj?.name}</Typography>
              <Typography> Phone : {data?.data?.userObj?.phone}</Typography>
              <Typography> Email : {data?.data?.userObj?.email}</Typography>
              <Typography> Address : {data?.data?.userObj?.address}</Typography>


            </Box>

            <Box sx={{ marginTop: 2, }}>
              <Typography variant='h6' >Order Details :  </Typography>
              <Typography>
                <Link to={`/admin/purchaseOrder/purchaseOrderDetail/${orderData?.data?._id}`}>View Order</Link>
              </Typography>
              <Typography> Order Id : {orderData?.data?.id}</Typography>
              <Typography> Request By : {orderData?.data?.requestUserObj?.name} ({orderData?.data?.requestUserObj?.phone})</Typography>
              <Typography> Fullfilled By : {orderData?.data?.userObj?.name} ({orderData?.data?.userObj?.phone})</Typography>

            </Box>

          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <Box sx={{ position: 'absolute', right: 3, top: 3 }}>
              <Tooltip title="Reload" onClick={() => refetch()} sx={{ backgroundColor: 'white' }}>
                <IconButton >
                  <Refresh color="inherit" />
                </IconButton>
              </Tooltip>

            </Box>
            <Box sx={{ flex: 1, border: '1px solid rgba(0,0,0,0.5)', borderRadius: 1, height: '60vh', minHeight: '60vh', maxHeight: '60vh', overflowY: 'scroll', }} ref={containerRef}>


              <Box sx={{ overflowY: 'scroll' }} >
                {
                  chatData?.map(el => (
                    // null
                    <ChatMessage chatObj={el} userName={data?.data?.userObj?.name} />
                  ))
                }


              </Box>

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 2 }} component={'form'} onSubmit={e => { e.preventDefault(); sendMessage() }}>
              <Box sx={{ flex: 1 }}>

                <TextField fullWidth variant='standard' value={message} onChange={e => setMessage(e.target.value)} placeholder='Message' />
              </Box>
              <Box>
                <Button type='submit' sx={{ height: "100%" }} >Send</Button>
              </Box>
            </Box>

          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
const ChatMessage = ({ chatObj, userName }) => {

  const fromUser = chatObj?.from === ROLES.USER


  return (
    <Box sx={
      fromUser ? {
        backgroundColor: '#d0d6df',
        borderRadius: 1,
        padding: 1,
        width: "80%",
        marginLeft: 2,
        marginTop: 2,
        marginBottom: 2,
      } : {
        backgroundColor: '#ddd',
        borderRadius: 1,
        padding: 1,
        width: "80%",
        marginLeft: 'auto',
        marginRight: 2,
        marginTop: 2,
        marginBottom: 2,
      }
    }>
      <Typography sx={{}}>{chatObj?.message}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='overline' >
          {format(new Date(chatObj?.createdAt), 'HH:mm, dd MMM yyyy')}
        </Typography>
        <Typography variant='overline'>{fromUser ? userName : "Admin"}</Typography>
      </Box>
    </Box>

  )

}
