import React, { useState } from 'react'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { addUser } from '../../../services/user.service';
import { ROLES } from '../../../utils/constants';
import { errorToast, successToast } from '../../../utils/toast.util';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';


export default function AddUser() {

  const [name, setName] = useState('');
  const [firmName, setFirmName] = useState('');
  const [gstNumber, setGSTNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(ROLES.USER)
  const [manageUsers, setManageUsers] = useState(true)
  const [manageCategory, setManageCategory] = useState(true)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      let formData = {
        email,
        password,
        name,
        phone,
        role,
        accessObj: {
          manageUsers,
          manageCategory
        },
        firmName,
        gstNumber,
        address,
        approved: true,
      }
      const res = await addUser(formData)

      successToast(res.data.message)

      navigate('/admin/user/view')
    }
    catch (err) {
      errorToast(err)
    }
  }
  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden', p: 2 }} component={'form'} onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
        <Typography variant='h5' sx={{ m: 1 }}>Add User</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
          <Box sx={{ flex: 1, marginRight: 1 }}>

            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} sx={{ m: 1 }} />
            <TextField fullWidth label="Contact Number" value={phone} onChange={e => setPhone(e.target.value)} sx={{ m: 1 }} />
            <TextField fullWidth label="Email" value={email} onChange={e => setEmail(e.target.value)} sx={{ m: 1 }} />
            <TextField fullWidth label="Address" value={address} onChange={e => setAddress(e.target.value)} sx={{ m: 1 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField fullWidth label="Firm Name" value={firmName} onChange={e => setFirmName(e.target.value)} sx={{ m: 1 }} />
            <TextField fullWidth label="GST number" value={gstNumber} onChange={e => setGSTNumber(e.target.value)} sx={{ m: 1 }} />
            <TextField fullWidth label="Password" value={password} onChange={e => setPassword(e.target.value)} sx={{ m: 1 }} />
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel>User Type</InputLabel>
              <Select
                value={role}
                label="User Type"
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value={ROLES.USER}>User (Buyer/Seller)</MenuItem>
                <MenuItem value={ROLES.SUBADMIN}>Sub Admin</MenuItem>
                <MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
              </Select>
            </FormControl>

          </Box>


        </Box>
        {
          role == ROLES.SUBADMIN &&
        <Box>

          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel sx={{ m: 1 }} control={<Checkbox checked={manageUsers} onChange={e => setManageUsers(e.target.checked)} />} label="Manage Users" />
            <FormControlLabel sx={{ m: 1 }} control={<Checkbox checked={manageCategory} onChange={e => setManageCategory(e.target.checked)} />} label="Manage Categories" />
          </FormGroup>

        </Box>

        }
        <Box>
          <Button sx={{ m: 1 }} type="submit" variant="contained">Submit</Button>
        </Box>
      </Paper>
    </Box>
  )
}
