import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
} from "react-router-dom";
import AddBrand from './AddBrand';
import ViewBrand from './ViewBrand';

export default function BrandRouter() {
    return (
        <Routes>
            <Route path="add" element={<AddBrand />} />
            <Route path="view" element={<ViewBrand />} />
            <Route path="*" element={<Navigate to="../view" relative="route" />} />
        </Routes>
    )
}
