import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addBrand, getById, updateBrandById } from "../../../services/brand.service";
import { getLocation } from "../../../services/location.service";
import { errorToast, successToast } from "../../../utils/toast.util";

export default function AddBrand() {

  const [name, setName] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([])

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams('')
  const [previousObj, setPreviousObj] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const { isLoading, isFetching, error, data: locationArr, refetch } = useQuery({
    queryKey: ['all_locations'],
    queryFn: () => getLocation({ pageIndex: 0, pageSize: 1000 }).then(res => res?.data?.data),
    onError: err => errorToast(err),
    keepPreviousData: true,
    initialData: []
  })




  const handleSubmit = async () => {
    try {

      let obj = {
        name,
        locationIdArr: selectedLocations
      };

      if (isUpdate) {
        const res = await updateBrandById(obj, previousObj?._id)
        if (res.data?.message)
          successToast(res.data?.message)
        navigate('/admin/brand/view')
      }
      else {

        const res = await addBrand(obj)
        console.log("RESPONSE", res.data)
        if (res.data?.message)
          successToast(res.data?.message)
        navigate('/admin/brand/view')
      }

    } catch (error) {
      errorToast(error)
    }
  };

  const isLocationSelected = (locationId) => {
    return selectedLocations.some(el => el.locationId == locationId)
  }


  const selectLocation = (locationId) => {
    console.log(locationId, "AD")
    if (isLocationSelected(locationId)) {
      setSelectedLocations(prev => prev.filter(el => el.locationId != locationId))
    }
    else {
      setSelectedLocations(prev => [...prev, { locationId }])
    }

  }


  const getPreviousBrand = async () => {
    try {
      const { data: res } = await getById(searchParams.get("id"));
      if (res) {
        setPreviousObj(res.data)
        setName(res?.data?.name)
        setSelectedLocations(res?.data?.locationIdArr)
        setIsUpdate(true)
      }
    } catch (error) {
      errorToast(error)
    }
  }

  useEffect(() => {
    if (searchParams.get("id")) {
      getPreviousBrand()
    }
  }, [searchParams.get("id")])






  return (
    <Box >
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', p: 2 }}>
        <Typography variant="h5" >{isUpdate ? "Update" : "Add"} Brand</Typography>
        <Box sx={{ marginTop: 1, marginBottom: 2 }} >
          <TextField value={name} onChange={(event) => setName(event.target.value)} label="Name" fullWidth />
        </Box>
        <Box>
          <Typography variant="h6">Locations:</Typography>

          {
            locationArr.map(el =>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={isLocationSelected(el._id)} onChange={e => selectLocation(el._id)} />} label={el.name} />
              </FormGroup>
            )
          }

        </Box>
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleSubmit} variant='contained' >Save</Button>
        </Box>
      </Paper>
    </Box>
  );
}

