import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../../utils/toast.util';
import { getAllSupportTickets } from '../../../services/support.service';
import { Box, Button, Paper } from '@mui/material';
import CustomTable from '../../../components/CustomTable';

export default function Support() {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const { isLoading, isFetching, error, data, refetch } = useQuery({
    queryKey: ['support_tickets', pagination],
    queryFn: () => getAllSupportTickets(pagination).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })


  const columns = useMemo(() => [
    {
      header: "Id",
      accessorFn: row => row?.id,
    },
    {
      header: "Issue",
      accessorFn: row => row?.issue,
    },
    {
      header: "Name",
      accessorFn: row => row?.userObj?.name,
    },
    {
      header: "View",
      cell: info => <Button variant='outlined' onClick={() => navigate(`/admin/support/details/${info.row?.original?._id}`) } >View</Button>, 
    },
  ], [navigate])




  const loading = isLoading || isFetching;



  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden' }} >

        <CustomTable
          label={'View Support Tickets'}
          columns={columns}
          data={data?.data}
          // totalCount={data?.data?.length}
          totalCount={data?.count}
          loading={loading}
          onChangePagination={setPagination}
          pagination={pagination}
          reload={refetch}
          serverPagination
        // labelExtraComponent={
        //   <>
        //     ASLDKAJLSKDJALKSJ
        //     <Button>Dowload</Button>
        //   </>
        // }
        // searchExtraComponent={
        //   <>
        //     asdfas
        //     <IconButton>
        //       <Download></Download>
        //     </IconButton>
        //   </>
        // }
        // searchStr={searchStr}
        // setSearchStr={setSearchStr}
        />
      </Paper>
    </Box>
  )
}
