import Url from './url.service';
import axios from './axios.service';
const serverUrl = `${Url}/support`;

export const createSupport = (obj) => {
    return axios.post(`${serverUrl}/`, obj);
};

export const getSupportById = (id) => {
    return axios.get(`${serverUrl}/getById/${id}`);
};

export const sendChat = (obj) => {
    return axios.post(`${serverUrl}/sendChat/`, obj);
};

export const getSupportTicketsByPurchaseId = (id) => {
    return axios.get(`${serverUrl}/getSupportTicketsByPurchaseId/${id}`);
}

export const getChatsBySupportTicketId = (id) => {
    return axios.get(`${serverUrl}/getChatsBySupportTicketId/${id}`);
}


export const getAllSupportTickets = (obj = { pageIndex: 0, pageSize: 10 }, search = '') => {
    let query = new URLSearchParams({ ...obj, search })
    return axios.get(`${serverUrl}/?${query.toString()}`)
}