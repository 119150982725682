import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/payment'


export const pay = (id, amountPaid) => {
    return axios.post(`${url}/pay/${id}`, { amountPaid })
}

export const getPastExpenditure = () => {
    return axios.get(`${url}/adminPaymentHistory`)
}

export const getPastPayments = () => {
    return axios.get(`${url}/pastAdminPaymentReceived`)
}

export const getUpcomingExpenditure = () => {
    return axios.get(`${url}/upcomingAdminExpenditure`)
}


export const getUpcomingPayments = () => {
    return axios.get(`${url}/upcomingAdminPayments`)
}