import React from 'react';
import {
    Navigate, Route, Routes
} from "react-router-dom";
import Payment from './Payment';

export default function PaymentRouter() {
    return (
        <Routes>
            <Route path="" element={<Payment />} />
            <Route path="*" element={<Navigate to="../" relative="route" />} />
        </Routes>
    )
}
