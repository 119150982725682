import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { errorToast, successToast } from '../../../utils/toast.util';
import { deleteProductById, getAllProducts } from '../../../services/product.service';
import { getAllRequests } from '../../../services/request.service';
import { useNavigate } from 'react-router-dom';
import { getAllPurchaseOrder } from '../../../services/purchaseOrder.service';
import { useQuery } from '@tanstack/react-query';
import CustomTable from '../../../components/CustomTable';

export default function ViewPurchaseOrder() {

  // const [rows, setRows] = useState([])

  // const [bids, setBids] = useState([])
  // const [bidModalVisible, setBidModalVisible] = useState(false)


  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const { isLoading, isFetching, error, data, refetch } = useQuery({
    queryKey: ['purchase_orders', pagination],
    queryFn: () => getAllPurchaseOrder(pagination).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })


  const columns = useMemo(() => [
    {
      header: "Id",
      accessorFn: row => row?.id,
      // cell: info => info.getValue(),
    },
    {
      header: "Total Amount",
      accessorFn: row => 'Rs. ' + row?.productsArr?.reduce((acc, el) => acc + el.finalAmount, 0),
    },
    {
      header: "Number of products",
      accessorFn: row => row?.productsArr?.length,
    },
    {
      header: "Fullfilled By",
      accessorFn: row => row?.userObj?.name,
    },
    {
      header: "Requested By",
      accessorFn: row => row?.requestUserObj?.name,
    },
    {
      header: "View",
      cell: info => <Button variant='outlined' onClick={() => navigate(`/admin/purchaseOrder/purchaseOrderDetail/${info.row?.original?._id}`) } >View</Button>, 
    },
  ], [navigate])


  // useEffect(() => {
  //   handleGet()
  // }, [])


  // const handleGet = async () => {
  //   try {
  //     const res = await getAllPurchaseOrder()
  //     if (res.data?.data)
  //       console.log(res.data.data, "res data")
  //     setRows(res.data.data)

  //   } catch (error) {
  //     errorToast(error)
  //   }
  // }


  const loading = isLoading || isFetching;
  // const handleDelete = async (id) => {
  //   try {
  //     const res = await deleteProductById(id)
  //     if (res?.data?.message)
  //       successToast(res.data.message)
  //     handleGet()

  //   } catch (error) {
  //     errorToast(error)
  //   }
  // }



  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden' }} >

        <CustomTable
          label={'View Purchase Order'}
          columns={columns}
          data={data?.data}
          // totalCount={data?.data?.length}
          totalCount={data?.count}
          loading={loading}
          onChangePagination={setPagination}
          pagination={pagination}
          reload={refetch}
          serverPagination
        // labelExtraComponent={
        //   <>
        //     ASLDKAJLSKDJALKSJ
        //     <Button>Dowload</Button>
        //   </>
        // }
        // searchExtraComponent={
        //   <>
        //     asdfas
        //     <IconButton>
        //       <Download></Download>
        //     </IconButton>
        //   </>
        // }
        // searchStr={searchStr}
        // setSearchStr={setSearchStr}
        />
      </Paper>
    </Box>
  )
}


