import React from 'react';
import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import AddMarque from './AddMarque';

export default function MarqueRouter() {
    return (
        <Routes>
            <Route path="add" element={<AddMarque />} />
        </Routes>
    )
}
