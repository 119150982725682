import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { errorToast, successToast } from '../../../utils/toast.util';
import { deleteProductById, getAllProducts } from '../../../services/product.service';
import { getAllRequests } from '../../../services/request.service';
import { Link, useNavigate } from 'react-router-dom';
import { getAllPurchaseOrder } from '../../../services/purchaseOrder.service';
import { getPastExpenditure, getPastPayments, getUpcomingExpenditure, getUpcomingPayments } from '../../../services/payment.service';

export default function Payment() {

  // const [rows, setRows] = useState([])



  // const navigate = useNavigate();



  // useEffect(() => {
  //   handleGet()
  // }, [])


  // const handleGet = async () => {
  //   try {
  //     const res = await getAllPurchaseOrder()
  //     if (res.data?.data)
  //       console.log(res.data.data, "res data")
  //     setRows(res.data.data)

  //   } catch (error) {
  //     errorToast(error)
  //   }
  // }




  return (
    <Box>
      <Grid container spacing={3}>


        <Grid item xs={12} md={6}>
          <UpcomingPayment />
        </Grid>

        <Grid item xs={12} md={6}>
          <UpcomingExpenditure />
        </Grid>


        <Grid item xs={12} md={6}>
          <PaymentReceived />
        </Grid>
        <Grid item xs={12} md={6}>
          <PaymentDone />
        </Grid>


      </Grid>

    </Box>
  )
}


const PaymentReceived = ({ rows }) => {


  const [pastArr, setPastArr] = useState([])

  const handleGet = async () => {
    try {
      const res = await getPastPayments()
      console.log("RES", res.data)
      if (res.data?.data) {
        setPastArr(res.data.data)
      }

    } catch (error) {
      errorToast(error)
    }
  }

  useEffect(() => {
    handleGet()
  }, [])

  const list = useMemo(() => {
    let val = pastArr
      // .filter(el => el.amountPaidToAdmin)
      .map(el => ({ _id: el._id, amountPaid: el.amountPaid, purchaseOrderId: el.purchaseOrderId }))
    return val
  }, [pastArr])

  const totalAmountReceived = useMemo(() => {
    return list.reduce((acc, el) => acc + el.amountPaid, 0)
  }, [list])

  return (<>
    <Paper sx={{ margin: 'auto', overflow: 'hidden', p: 2 }} >

      <Typography variant='h5' sx={{ m: 1 }}>Payment Recieved</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sno</TableCell>
                <TableCell >Id</TableCell>
                <TableCell >Amount Received</TableCell>
                <TableCell >View Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, i) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.amountPaid}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={`/admin/purchaseOrder/purchaseOrderDetail/${row.purchaseOrderId}`}>View Order</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ ml: 2 }}>
          <Typography variant='h6'>Total Received: ₹{totalAmountReceived}</Typography>
        </Box>

      </Box>

    </Paper>
  </>)

}


const PaymentDone = ({ rows }) => {

  const [pastArr, setPastArr] = useState([])

  const handleGet = async () => {
    try {
      const res = await getPastExpenditure()
      console.log("RES", res.data)
      if (res.data?.data) {
        setPastArr(res.data.data)
      }

    } catch (error) {
      errorToast(error)
    }
  }

  useEffect(() => {
    handleGet()
  }, [])

  const list = useMemo(() => {
    let val = pastArr
      // .filter(el => el.amountPaidToAdmin)
      .map(el => ({ _id: el._id, amountPaid: el.amountPaid, purchaseOrderId: el.purchaseOrderId }))
    return val
  }, [pastArr])

  const totalAmountPaid = useMemo(() => {
    return list.reduce((acc, el) => acc + el.amountPaid, 0)
  }, [list])

  return (<>
    <Paper sx={{ margin: 'auto', overflow: 'hidden', p: 2 }} >

      <Typography variant='h5' sx={{ m: 1 }}>Payment Done</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sno</TableCell>
                <TableCell >Id</TableCell>
                <TableCell >Amount Received</TableCell>
                <TableCell >View Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, i) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.amountPaid}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Link to={`/admin/purchaseOrder/purchaseOrderDetail/${row.purchaseOrderId}`}>View Order</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ ml: 2 }}>
          <Typography variant='h6'>Total Amount Paid: ₹{totalAmountPaid}</Typography>
        </Box>

      </Box>

    </Paper>
  </>)


}

const UpcomingPayment = () => {

  const navigate = useNavigate()

  const [upcomingArr, setUpcomingArr] = useState([])


  useEffect(() => {

    handleGet()

  }, [])

  const handleGet = async () => {
    try {
      const res = await getUpcomingPayments()
      console.log(res.data?.data, "ASDF")
      if (res.data?.data) {
        setUpcomingArr(res.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }



  const list = useMemo(() => {
    let val = upcomingArr
      .map(el => ({ _id: el._id, amountLeft: el.totalAmount - el.amountPaid, purchaseOrderId: el.purchaseOrderId }))
    return val
  }, [upcomingArr])

  const totalUpcomingPayment = useMemo(() => {
    return list.reduce((acc, el) => acc + el.amountLeft, 0)
  }, [list])

  return (<>
    <Paper sx={{ margin: 'auto', overflow: 'hidden', p: 2 }} >

      <Typography variant='h5' sx={{ m: 1 }}>Upcoming Payment</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sno</TableCell>
                <TableCell >Id</TableCell>
                <TableCell >Upcoming Payment</TableCell>
                <TableCell >View Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, i) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.amountLeft}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={`/admin/purchaseOrder/purchaseOrderDetail/${row.purchaseOrderId}`}>View Order</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ ml: 2 }}>
          <Typography variant='h6'>Total Upcoming Payment: ₹{totalUpcomingPayment}</Typography>
        </Box>

      </Box>

    </Paper>
  </>)



}

const UpcomingExpenditure = ({ rows }) => {



  const [upcomingArr, setUpcomingArr] = useState([])


  useEffect(() => {

    handleGet()

  }, [])

  const handleGet = async () => {
    try {
      const res = await getUpcomingExpenditure()
      console.log(res.data?.data, "ASDF")
      if (res.data?.data) {
        setUpcomingArr(res.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }


  const list = useMemo(() => {
    let val = upcomingArr
      // .filter(el => el.totalAmount - el.amountPaid> 0)
      .map(el => ({ _id: el._id, expenditure: el.totalAmount - el.amountPaid, purchaseOrderId: el.purchaseOrderId }))
    return val
  }, [upcomingArr])

  const upcomingExpenditure = useMemo(() => {
    return list.reduce((acc, el) => acc + el.expenditure, 0)
  }, [list])

  return (<>
    <Paper sx={{ margin: 'auto', overflow: 'hidden', p: 2 }} >

      <Typography variant='h5' sx={{ m: 1 }}>Upcoming Expenditure</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sno</TableCell>
                <TableCell >Id</TableCell>
                <TableCell >Upcoming Expenditure</TableCell>
                <TableCell >View Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, i) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.expenditure}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Link to={`/admin/purchaseOrder/purchaseOrderDetail/${row.purchaseOrderId}`}>View Order</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ ml: 2 }}>
          <Typography variant='h6'>Total Upcoming Expenditure: ₹{upcomingExpenditure}</Typography>
        </Box>

      </Box>

    </Paper>
  </>)




}