import './App.css';

import RootRouter from './RootRouter';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <RootRouter />
      <Toaster />
    </>
  );
}

export default App;
