import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { errorToast, successToast } from '../../../utils/toast.util';
import CustomTable from '../../../components/CustomTable';
import { useQuery } from '@tanstack/react-query';
import { deleteLocationById, getLocation } from '../../../services/location.service';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function ViewLocation() {


  const navigate = useNavigate()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const { isLoading, isFetching, error, data, refetch } = useQuery({
    queryKey: ['locations', pagination],
    queryFn: () => getLocation(pagination).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })


  const loading = isLoading || isFetching;


  // const [rows, setRows] = useState([])

  // useEffect(() => {
  //   handleGet()
  // }, [])
  // const handleGet = async () => {
  //   try {
  //     const res = await getAllProducts()
  //     if (res.data?.data)
  //       setRows(res.data.data)

  //   } catch (error) {
  //     errorToast(error)
  //   }
  // }


  const handleDelete = async (id) => {
    try {
      const res = await deleteLocationById(id)
      if (res?.data?.message)
        successToast(res.data.message)
      refetch()

    } catch (error) {
      errorToast(error)
    }
  }


  const handleEdit = (id) => {
    navigate(`/admin/location/add?id=${id}`)
  }


  const columns = useMemo(() => [
    {
      header: "Name",
      accessorFn: row => row?.name,
      // cell: info => info.getValue(),
    },
    // {
    //   header: "Category",
    //   accessorFn: row => row.categoryId?.name,
    // },
    {
      header: "Edit",
      cell: info => <Button variant='outlined' color='warning' onClick={() => handleEdit(info.row?.original?._id)}>Edit</Button>,
    },
    {
      header: "Delete",
      cell: info => <Button variant='outlined' color='error' onClick={() => handleDelete(info.row?.original?._id)}>Delete</Button>,
    },
  ], [])





  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden' }} >

        <CustomTable
          label={'View Location'}
          columns={columns}
          data={data?.data}
          totalCount={data?.data?.length}
          // totalCount={data?.count}
          loading={loading}
          // onChangePagination={setPagination}
          // pagination={pagination}
          reload={refetch}
        // serverPagination
        // labelExtraComponent={
        //   <Box sx={{ marginLeft: 'auto' }}>
        //     <Link to='/admin/location/add'>
        //       <Typography>
        //         Add
        //       </Typography>
        //     </Link>
        //   </Box>
        // }
        // searchExtraComponent={
        //   <>
        //     asdfas
        //     <IconButton>
        //       <Download></Download>
        //     </IconButton>
        //   </>
        // }
        // searchStr={searchStr}
        // setSearchStr={setSearchStr}
        />



      </Paper>
    </Box>
  )
}
