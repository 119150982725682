import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
} from "react-router-dom";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import ViewUser from "./ViewUser";
import UserContainer from "./UserContainer";

export default function UserRouter() {
    return (
        <Routes>

            {/* <Route path="" element={<UserContainer />} /> */}
            <Route path="add" element={<AddUser />} />
            <Route path="view" element={<ViewUser />} />
            <Route path="edit/:id" element={<EditUser />} />
            <Route path="*" element={<Navigate to="../view" relative="route" />} />
        </Routes>
    )
}
