import React, { useEffect, useState } from "react";
import SelectNestedCategory from "../../../components/SelectNestedCategory";
import { addCategory, getNestedCategories } from "../../../services/category.service";
import { errorToast, successToast } from "../../../utils/toast.util";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { addProduct, getById, updateProductById } from "../../../services/product.service";
import FileUpload from "../../../utils/FileUpload";
import { generateFilePath } from "../../../services/url.service";

function AddProduct({ makeChange = false }) {

  const [name, setName] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState("");
  const [categoryArr, setCategoryArr] = useState([]);
  const params = useParams();
  const [previousObj, setPreviousObj] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [imageStr, setImageStr] = useState("");




  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      let cat_arr = returnSelectedCategories(mainCategoryArr);
      console.log(mainCategoryArr, "mainCategoryArr")
      let obj = {
        name,
        imageStr,
        categoryId: cat_arr[cat_arr.length - 1]._id,
        categoryArr: cat_arr.map((el) => {
          return { categoryId: el._id, level: el.level };
        }),
      };

      obj.level = obj.categoryArr[obj.categoryArr.length - 1]?.level ? obj.categoryArr[obj.categoryArr.length - 1]?.level : 1


      console.log(obj, "product obj");

      if (searchParams.get('id')) {
        const res = await updateProductById(obj, searchParams.get('id'))
        console.log("RESPONSE", res.data)
        if (res.data?.message)
          successToast(res.data?.message)
      }
      else {
        const res = await addProduct(obj)
        console.log("RESPONSE", res.data)
        if (res.data?.message)
          successToast(res.data?.message)
      }
      navigate('/admin/product/view')

    } catch (error) {
      errorToast(error)
    }
  };

  const getLocationById = async () => {
    try {
      const { data: res } = await getById(searchParams.get("id"));
      if (res) {
        setIsUpdate(true)
        setPreviousObj(res?.data)
        if (res?.data?.name) {
          setName(res?.data?.name)
          setImageStr(res?.data?.image)

          if (res.data?.categoryArr) {
            setSelectedCategories(res.data?.categoryArr)
          }
        }
      }
    } catch (error) {
      errorToast(error)
    }
  }


  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);




  const getCatData = async () => {
    try {
      const res = await getNestedCategories()
      if (res.data?.data)
        setCategoryArr(res.data?.data)

    } catch (error) {
      errorToast(error)
    }
  }


  const handleCategorySelectOnInit = (selectedCategoryArrFromDB, categoryArr) => {
    let tempArr = categoryArr.map((el) => {
      if (selectedCategoryArrFromDB.some((ele) => ele.categoryId == el._id)) {
        el.checked = true;
      }
      if (el.subCategoryArr) {
        handleCategorySelectOnInit(selectedCategoryArrFromDB, el.subCategoryArr);
      }
      return el;
    });
  };

  useEffect(() => {
    if (previousObj && categoryArr && categoryArr.length > 0 && previousObj.categoryIdArr && previousObj.categoryIdArr.length > 0) {
      handleCategorySelectOnInit(previousObj.categoryIdArr, categoryArr);
    }
  }, [categoryArr, previousObj]);



  useEffect(() => {
    getCatData()
    if (searchParams.get('id')) {
      getLocationById()
    }
  }, [searchParams.get("id")])



  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map((el) => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some((el) => el != el._id)) {
            tempArr.push(el._id);
          }
          setSelectedCategoryArr([...tempArr]);
          return { ...el, categoryId: el._id, subCategoryArr: handleFilterChecked(el.subCategoryArr) };
        } else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some((el) => el != el._id)) {
              tempArr.push(el._id);
            }
            setSelectedCategoryArr([...tempArr]);
            return { ...el, categoryId: el._id };
          }
        }
      });
    } else {
      return arr;
    }
  };



  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    let subCategories = arr.reduce((acc, el) => [...acc, ...el.subCategoryArr.filter((el) => el.checked)], []);
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)];
    } else {
      return [...new_selected_arr];
    }
  };


  const handleRenderNestedCategory = (arr, id, value, level) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.level == level) {
          el.checked = false
        }
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value, level);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };


  const handleNestedCategoryCheckBoxEvent = (id, value, level) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.level == level) {
          el.checked = false
          if (el.subCategoryArr && el.subCategoryArr.length > 0) {
            el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value, level);
            return el;
          } else {
            return el;
          }
        }
        else {
          if (el.subCategoryArr && el.subCategoryArr.length > 0) {
            el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value, level);
            return el;
          } else {
            return el;
          }
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr]);
  };

  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div className="col-12 mb-3" style={{ marginLeft: `${obj.level + 5}px` }}>
        <input className="form-check-input pointer" checked={obj.checked} onChange={(event) => handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked, obj.level)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>{obj.name}</label>
        {obj.checked &&
          obj.subCategoryArr &&
          obj.subCategoryArr.length > 0 &&
          obj.subCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
      </div>
    );
  };


  return (
    <Box >
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', p: 2 }}>
        <Typography variant="h5" >{isUpdate ? "Update" : "Add"} Product</Typography>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <TextField value={name} onChange={(event) => setName(event.target.value)} label="Name" fullWidth />
        </div>
        <h4 className="blue-1">Product Category</h4>
        {mainCategoryArr &&
          mainCategoryArr.length > 0 &&
          mainCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
        <div>
          {
            imageStr && imageStr.includes("base64") ?
              <img src={imageStr} style={{ height: 100, width: 100 }} alt="" />
              :
              <img src={generateFilePath(imageStr)} style={{ height: 100, width: 100 }} alt="" />
          }
        </div>
        <FileUpload acceptImage={true} onFileChange={(value) => setImageStr(value)} />
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleSubmit} variant='contained' >Save</Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default AddProduct;
