import decodeJwt from 'jwt-decode'


export const getDecodedJwt = () => {
    let token = getJwt();
    if (token) {
        let decodedObj = decodeJwt(token);
        return decodedObj
    }
    return null
}


export const setJwt = (val) => {
    let token = localStorage.setItem('AUTH_TOKEN', val);
    return token
}

export const getJwt = () => {
    let token = localStorage.getItem('AUTH_TOKEN');
    return token
}

export const removeJwt = () => {
    localStorage.removeItem('AUTH_TOKEN');
    return;
}
