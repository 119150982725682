import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import Content from './Content';
import Header from './Header';
// This is a React Router v6 app
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import UserRouter from '../pages/admin/user/UserRouter';
import CategoryRouter from '../pages/admin/category/CategoryRouter';
import ProductRouter from '../pages/admin/product/ProductRouter';
import RequestRouter from '../pages/admin/request/RequestRouter';
import PurchaseOrderRouter from '../pages/admin/purchaseOrder/PurchaseOrderRouter';
import PaymentRouter from '../pages/admin/payment/PaymentRouter';
import SupportRouter from '../pages/admin/support/SupportRouter';
import LocationRouter from '../pages/admin/location/LocationRouter';
import BrandRouter from '../pages/admin/brand/BrandRouter';
import MarqueRouter from '../pages/admin/Marque/MarqueRouter';
import BlogRouter from '../pages/admin/blog/BlogRouter';

function Copyright() {
  return (<></>)
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="https://ebslon.com/" >
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#1976d2',
      dark: '#000000',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};


export default function Paperbase() {

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header />
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
            <Routes>
              <Route path='user/*' element={<UserRouter />} />
              <Route path='category/*' element={<CategoryRouter />} />
              <Route path='product/*' element={<ProductRouter />} />
              <Route path='request/*' element={<RequestRouter />} />
              <Route path='purchaseOrder/*' element={<PurchaseOrderRouter />} />
              <Route path='payment/*' element={<PaymentRouter />} />
              <Route path='support/*' element={<SupportRouter />} />
              <Route path='location/*' element={<LocationRouter />} />
              <Route path='brand/*' element={<BrandRouter />} />
              <Route path='marque/*' element={<MarqueRouter />} />
              <Route path='blog/*' element={<BlogRouter />} />
              <Route path='*' element={<>404</>} />
            </Routes>
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
