import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate
} from "react-router-dom";
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import ViewCategory from './ViewCategory';

export default function CategoryRouter() {
    return (
        <Routes>
            <Route path="add" element={<AddCategory />} />
            <Route path="view" element={<ViewCategory />} />
            <Route path="edit/:id" element={<AddCategory />} />
            <Route path="*" element={<Navigate to="../view" relative="route" />} />
        </Routes>
    )
}
