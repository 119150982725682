import { Box, Button, Collapse, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteProductById } from '../../../services/product.service';
import { getAllPurchaseOrder, getPurchaseOrderById, updatePurchaseOrderById } from '../../../services/purchaseOrder.service';
import { errorToast, successToast } from '../../../utils/toast.util';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';

import Select from 'react-select'
import { Order_Status_Arr, ROLES } from '../../../utils/constants';
import { pay } from '../../../services/payment.service';
export default function ViewPurchaseOrderDetail() {

  const [requestObj, setRequestObj] = useState({})

  const [bids, setBids] = useState([])

  const params = useParams()
  const navigate = useNavigate();

  const [orderStatus, setOrderStatus] = useState("ORDERED");

  const [adminAmount, setAdminAmount] = useState(0)
  const [amountPaidToAdmin, setAmountPaidToAdmin] = useState(0)
  const [amountPaidToSeller, setAmountPaidToSeller] = useState(0)

  const [adminPaymentObj, setAdminPaymentObj] = useState(null)
  const [adminExpenditureObj, setAdminExpenditureObj] = useState(null)


  useEffect(() => {
    handleGet()
    // throw new Error('error')
  }, [])


  const handleGet = async () => {
    try {
      const res = await getPurchaseOrderById(params.id)
      if (res.data?.data) {
        console.log(res.data.data, "res data")
        setRequestObj(res.data.data)

        if (res.data?.data?.paymentArr?.length) {
          let obj1 = res.data.data.paymentArr?.find(el => el.from === ROLES.ADMIN)
          let obj2 = res.data.data.paymentArr?.find(el => el.to === ROLES.ADMIN)
          setAdminExpenditureObj(obj1)
          setAdminPaymentObj(obj2)

        }

        // if (res.data.data?.amountPaidToAdmin) {
        //   setAmountPaidToAdmin(res.data.data?.amountPaidToAdmin)
        // }
        // if (res.data.data?.amountPaidToSeller) {
        //   setAmountPaidToSeller(res.data.data?.amountPaidToSeller)
        // }
        setOrderStatus(res.data.data.orderStatus)
      }

    } catch (error) {
      errorToast(error)
    }
  }

  const confirmDeliverySubmit = async () => {
    try {
      let { data: res } = await updatePurchaseOrderById(params.id, { confirmDelivery: true });
      if (res) {

        successToast("Confirmed")
        handleGet()
      }
    } catch (error) {
      errorToast(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let { data: res } = await updatePurchaseOrderById(params.id, { orderStatus });
      if (res) {

        successToast(res.message)
        handleGet()
      }
    } catch (error) {
      errorToast(error)
    }
  }

  const handleSubmitAdminAmount = async () => {
    try {
      if (adminPaymentObj && amountPaidToAdmin) {

        let { data: res } = await pay(adminPaymentObj._id, amountPaidToAdmin);
        setAmountPaidToAdmin(0)
        if (res) {
          successToast(res.message)
          handleGet()
        }
      }
    } catch (error) {
      errorToast(error)
    }
  }

  const handleSubmitUserAmount = async () => {
    try {
      if (adminExpenditureObj && amountPaidToSeller) {
        let { data: res } = await pay(adminExpenditureObj?._id, amountPaidToSeller);
        setAmountPaidToSeller(0)
        if (res) {
          successToast(res.message)
          handleGet()
        }
      }
    } catch (error) {
      errorToast(error)
    }
  }



  const adminTotalMount = useMemo(() => {
    return requestObj?.productsArr && requestObj?.productsArr.length > 0 && requestObj?.productsArr?.reduce((acc, el) => acc + el.finalAmount, 0)
  }, [requestObj])

  const userTotalMount = useMemo(() => {
    return requestObj?.productsArr && requestObj?.productsArr.length > 0 && requestObj?.productsArr?.reduce((acc, el) => acc + el.userAmount, 0)
  }, [requestObj])


  const DisplayRow = ({ row, index }) => {
    const [open, setOpen] = useState(false)

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{ maxWidth: 50 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.reduce((acc, el, index) => acc + `${el.userObj.name} ${index != row.productArr.length - 1 ? "," : ""}`, "")}
          </TableCell>
          <TableCell component="th" scope="row">
            ₹{row.reduce((acc, el, index) => acc + el.userAmount, 0)}
          </TableCell>
          <TableCell component="th" scope="row">
            {format(new Date(row.createdAt), 'dd MMM yyyy')}
          </TableCell>
          <TableCell component="th" scope="row">
            ₹{row.reduce((acc, el, index) => acc + el.commission, 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Products :
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sno</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Commission</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.productsArr.map((productObj, i) => (
                      <TableRow key={productObj._id}>
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell>{productObj.name}</TableCell>
                        <TableCell>{productObj.quantity}</TableCell>
                        <TableCell>₹{productObj.userAmount} </TableCell>
                        <TableCell>₹{productObj.commission} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )

  }



  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden', p: 2 }} >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ m: 1 }}>Purchase Order Id: {requestObj?.id}</Typography>
        </Box>

        <Typography variant='h6' sx={{ m: 1, mt: 3 }}>Requested By:  {requestObj?.requestUserObj?.name}</Typography>
        <Typography variant='h6' sx={{ m: 1, mt: 3 }}>Fulfilled By : {requestObj?.userObj?.name}</Typography>

        {
          requestObj?.confirmDelivery ?

            <Box sx={{ m: 1 }}>

              <Typography variant='overline' sx={{ fontWeight: 'bold' }} color={'green'}>
                Delivery Confirmed
              </Typography>
            </Box>
            :
            <Grid container>
              <Grid item md={2}>
                <Select placeholder="Order status ..." onChange={(e) => setOrderStatus(e.value)} options={Order_Status_Arr} value={{ label: orderStatus, value: orderStatus }} />
              </Grid>



              <Grid item md={2} sx={{ ml: 2 }}>
                <Button variant='outlined' onClick={() => handleSubmit()} >Update</Button>
                {
                  requestObj?.orderStatus === 'DELIVERED' &&
                  <Button variant='contained' sx={{ ml: 2 }} onClick={() => confirmDeliverySubmit()} >Confirm Delivery</Button>
                }

              </Grid>
            </Grid>
        }

        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell sx={{ maxWidth: 50 }}></TableCell> */}
                  <TableCell>Sno</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Commission</TableCell>
                  <TableCell>Final Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestObj?.productsArr && requestObj?.productsArr.length > 0 && requestObj?.productsArr.map((row, i) => (

                  <TableRow key={row?._id}>
                    <TableCell>
                      {i + 1}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>₹{row.userAmount} </TableCell>
                    <TableCell>₹{row.commission} </TableCell>
                    <TableCell>₹{row.finalAmount} </TableCell>
                  </TableRow>


                  // <DisplayRow key={row._id} row={row} index={i} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant='h5' sx={{ m: 1, mt: 3 }}>Total amount : {requestObj?.productsArr && requestObj?.productsArr.length > 0 && requestObj?.productsArr?.reduce((acc, el) => acc + el.finalAmount, 0)}</Typography>


          <Grid container spacing={3} sx={{ pt: 5 }}>
            <Grid xs={12} md={6} item >
              <Paper sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ m: 1, }}>Amount To Be Recieved</Typography>
                <Typography sx={{ m: 1, }}>Total Amount : {adminPaymentObj?.totalAmount}</Typography>
                <Typography sx={{ m: 1, }}>Amount Recieved : {adminPaymentObj?.amountPaid}</Typography>
                <Typography sx={{ m: 1, }}>Pending Amount : {adminPaymentObj?.totalAmount - adminPaymentObj?.amountPaid}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                  <TextField
                    label="Mark Payment to Admin"
                    value={amountPaidToAdmin}
                    onChange={(e) => setAmountPaidToAdmin(e.target.value)}
                  />

                  <Button variant='outlined' onClick={() => handleSubmitAdminAmount()} sx={{ ml: 2 }} >Mark Payment</Button>
                </Box>
              </Paper>

            </Grid>
            <Grid xs={12} md={6} item >
              <Paper sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ m: 1, }}>Amount To Be Sent</Typography>
                <Typography sx={{ m: 1, }}>Total Amount : {adminExpenditureObj?.totalAmount}</Typography>
                <Typography sx={{ m: 1, }}>Amount Recieved : {adminExpenditureObj?.amountPaid}</Typography>
                <Typography sx={{ m: 1, }}>Pending Amount : {adminExpenditureObj?.totalAmount - adminExpenditureObj?.amountPaid}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                  <TextField
                    label="Mark Payment to Seller"
                    value={amountPaidToSeller}
                    onChange={(e) => setAmountPaidToSeller(e.target.value)}
                  />

                  <Button variant='outlined' onClick={() => handleSubmitUserAmount()} sx={{ ml: 2 }} >Mark Payment</Button>
                </Box>
              </Paper>
            </Grid>

          </Grid>



        </Box>

      </Paper>
    </Box >
  )
}


