import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
} from "react-router-dom";
import Login from './pages/Login';
import Paperbase from './paperbase/Paperbase';
import { useSelector } from 'react-redux';
import ProtectedRoute, { useLoginRedirectPath } from './components/ProtectedRoute';
import RequestDeleteAccount from './pages/RequestDeleteAccount';


export default function RootRouter() {
    const isAuthorized = useSelector(state => state.auth.isAuthorized)
    const redirectPath = useLoginRedirectPath()

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute isAllowed={!isAuthorized} redirectPath={redirectPath} >
                        <Login />
                    </ProtectedRoute>
                } />
                <Route path="login" element={
                    <ProtectedRoute isAllowed={!isAuthorized} redirectPath={redirectPath} >
                        <Login />
                    </ProtectedRoute>
                } />
                <Route path="admin/*" element={
                    <ProtectedRoute isAllowed={isAuthorized} redirectPath={'/'} >
                        <Paperbase />
                    </ProtectedRoute>
                } />

                <Route path="requestDeleteAccount" element={<>
                    <RequestDeleteAccount />
                </>} />

                <Route path="*" element={<>
                    <Navigate to={'/'} replace />
                </>} />
            </Routes>
        </BrowserRouter>
    )
}
