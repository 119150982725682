import { ROLES } from "./constants"
import { getDecodedJwt } from "./jwt.util"

export const getRedirectUrl = () => {
    const jwt = getDecodedJwt()
    console.log("DECODED", jwt)
    if (jwt) {
        if (jwt.role == ROLES.ADMIN) {
            return '/admin/user/view'
        }
        if (jwt.role == ROLES.SUBADMIN) {
            // check access and return
            if (jwt?.user?.accessObj?.manageUsers) {
                return '/admin/user/view'
            }
            if (jwt?.user?.accessObj?.manageCategory) {
                return '/admin/category/view'
            }
        }
        if (jwt.role == ROLES.USER) {
            return '/'
        }

    }
    else {
        return '/'
    }
}