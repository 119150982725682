import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/bid'


export const getAllBids = () => {
    return axios.get(`${url}/`)
}

export const getBidsByRequestId = (id) => {
    return axios.get(`${url}/getByRequestId/${id}`)
}


export const deleteBidById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateProductById = (formData, id) => {
    return axios.patch(`${url}/updateById/${id}`, formData);
};


export const addCommission = (formData, id) => {
    return axios.patch(`${url}/addCommission/${id}`, formData);
};