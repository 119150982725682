import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
} from "react-router-dom";
import AddLocation from './AddLocation';
import ViewLocation from './ViewLocation';

export default function LocationRouter() {
    return (
        <Routes>
            <Route path="add" element={<AddLocation />} />
            <Route path="view" element={<ViewLocation />} />
            <Route path="*" element={<Navigate to="../view" relative="route" />} />
        </Routes>
    )
}
