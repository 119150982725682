import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/purchaseOrder'


export const getAllPurchaseOrder = ( obj = { pageIndex: 0, pageSize: 10 }, search = '') => {
    let query = new URLSearchParams({ ...obj, search })
    return axios.get(`${url}/?${query.toString()}`)
}


export const getPurchaseOrderById = (id) => {
    return axios.get(`${url}/getPurchaseOrderById/${id}`)
}
export const updatePurchaseOrderById = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
