import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/location'


export const getLocation = (obj = { pageIndex: 0, pageSize: 10 }, search = '') => {
    return axios.get(`${url}/`)
}

export const deleteLocationById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateLocationById = (formData, id) => {
    return axios.patch(`${url}/updateById/${id}`, formData);
};


export const addLocation = (formData) => {
    return axios.post(`${url}/`, formData);
};

export const getById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}