import React, { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../utils/toast.util";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addLocation, getById, updateLocationById } from "../../../services/location.service";


function AddLocation({ makeChange = false }) {

  const [name, setName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams()
  const [previousObj, setPreviousObj] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [locationCode, setLocationCode] = useState("");

  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {



      let obj = {
        name,
        locationCode,
      };
      if (isUpdate) {

        const res = await updateLocationById(obj, previousObj?._id)
        if (res.data?.message)
          successToast(res.data?.message)
        navigate('/admin/location/view')
      }
      else {

        const res = await addLocation(obj)
        if (res.data?.message)
          successToast(res.data?.message)
        navigate('/admin/location/view')
      }

    } catch (error) {
      errorToast(error)
    }
  };

  const getLocationById = async () => {
    try {
      const { data: res } = await getById(searchParams.get("id"));
      if (res) {
        setIsUpdate(true)
        setPreviousObj(res?.data)
        if (res?.data?.name) {
          setName(res?.data?.name)
        }
      }
    } catch (error) {
      errorToast(error)
    }
  }


  useEffect(() => {
    if (searchParams.get('id')) {
      getLocationById()
    }
  }, [searchParams.get("id")])


  return (
    <Box >
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', p: 2 }}>
        <Typography variant="h5" >{isUpdate ? "Update" : "Add"} Location</Typography>
        <Box sx={{ marginTop: 1, marginBottom: 2 }} >
          <TextField value={name} onChange={(event) => setName(event.target.value)} label="Name" fullWidth />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleSubmit} variant='contained' >Save</Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default AddLocation;
