import React, { useState } from 'react'
import { errorToast, successToast } from '../utils/toast.util'
import { addRequestDelete } from '../services/requestDelete.service'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'

export default function RequestDeleteAccount() {

    const [username, setUsername] = useState('')
    const [message, setMessage] = useState('')

    async function handleSubmit() {
        try {

            if (!username) {
                throw new Error('Phone is required')
            }
            const res = await addRequestDelete({ username, message })
            if (res.data?.message) {
                successToast(res.data?.message)
            }

        } catch (error) {
            errorToast(error)
        }
    }

    return (
        <Box sx={{ minHeight: '100vh', minWidth: '100vw', display: 'grid', placeItems: 'center', backgroundColor: '#115' }} component={'form'} onSubmit={(e) => { e?.preventDefault(); handleSubmit(); }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: 'min(400px, 45vh)' }} >
                <Typography variant='h4'>Request Account Deletion</Typography>
                <TextField label="Phone" value={username} onChange={e => setUsername(e.target.value)} sx={{ my: 1 }} />
                <TextField label="Message (if any)" value={message} onChange={e => setMessage(e.target.value)} sx={{ my: 1 }} />
                <Button variant='contained' type='submit'>Submit</Button>
            </Paper>
        </Box>
    )
}
