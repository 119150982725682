import { Box, Button, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../components/CustomTable';
import { deleteProductById, getAllProducts } from '../../../services/product.service';
import { errorToast, successToast } from '../../../utils/toast.util';

export default function ViewProduct() {
  const navigate = useNavigate()


  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const { isLoading, isFetching, error, data, refetch } = useQuery({
    queryKey: ['products', pagination],
    queryFn: () => getAllProducts(pagination).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })


  const loading = isLoading || isFetching;


  // const [rows, setRows] = useState([])

  // useEffect(() => {
  //   handleGet()
  // }, [])
  // const handleGet = async () => {
  //   try {
  //     const res = await getAllProducts()
  //     if (res.data?.data)
  //       setRows(res.data.data)

  //   } catch (error) {
  //     errorToast(error)
  //   }
  // }


  const handleDelete = async (id) => {
    try {
      const res = await deleteProductById(id)
      if (res?.data?.message)
        successToast(res.data.message)
      refetch()

    } catch (error) {
      errorToast(error)
    }
  }

  const handleEdit = (id) => {
    navigate(`/admin/product/add?id=${id}`)
  }




  const columns = useMemo(() => [
    {
      header: "Name",
      accessorFn: row => row?.name,
      // cell: info => info.getValue(),
    },
    {
      header: "Category",
      accessorFn: row => row.categoryId?.name,
    },
    {
      header: "Edit",
      cell: info => <Button variant='outlined' color='warning' onClick={() => handleEdit(info.row?.original?._id)}>Edit</Button>,
    },
    {
      header: "Delete",
      cell: info => <Button variant='outlined' color='error' onClick={() => handleDelete(info.row?.original?._id)}>Delete</Button>,
    },
  ], [])





  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden' }} >

        <CustomTable
          label={'View Products'}
          columns={columns}
          data={data?.data}
          totalCount={data?.data?.length}
          // totalCount={data?.count}
          loading={loading}
          // onChangePagination={setPagination}
          // pagination={pagination}
          reload={refetch}
        // serverPagination
        // labelExtraComponent={
        //   <>
        //     <Button>Dowload</Button>
        //   </>
        // }
        // searchExtraComponent={
        //   <>
        //     asdfas
        //     <IconButton>
        //       <Download></Download>
        //     </IconButton>
        //   </>
        // }
        // searchStr={searchStr}
        // setSearchStr={setSearchStr}
        />



      </Paper>
    </Box>
  )
}
