import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/marquee'


export const getmarquee = () => {
    return axios.get(`${url}/`)
}


export const addmarquee = (formData) => {
    return axios.post(`${url}/`, formData);
};

