import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addBlogs, getById, updateBlogById } from "../../../services/blog.service";
import { getLocation } from "../../../services/location.service";
import { errorToast, successToast } from "../../../utils/toast.util";
import FileUpload from "../../../utils/FileUpload";
import { generateFilePath } from "../../../services/url.service";

export default function AddBlog() {

  const [heading, setHeading] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams('')

  const navigate = useNavigate()

  const [previousObj, setPreviousObj] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);





  const handleSubmit = async () => {
    try {

      let obj = {
        heading,
        message,
        image
      };
      console.log(obj, "obj")
      if (isUpdate) {
        const res = await updateBlogById(obj, previousObj?._id)
        if (res.data?.message)
          successToast(res.data?.message)
        navigate('/admin/blog/view')
      }
      else {

        const res = await addBlogs(obj)
        console.log("RESPONSE", res.data)
        if (res.data?.message)
          successToast(res.data?.message)
        navigate('/admin/blog/view')
      }

    } catch (error) {
      errorToast(error)
    }
  };



  const getPreviousBrand = async () => {
    try {
      const { data: res } = await getById(searchParams.get("id"));
      if (res) {
        setPreviousObj(res.data)
        setHeading(res?.data?.heading)
        setMessage(res?.data?.message)
        setImage(res?.data?.image)
        setIsUpdate(true)
      }
    } catch (error) {
      errorToast(error)
    }
  }

  useEffect(() => {
    if (searchParams.get("id")) {
      getPreviousBrand()
    }
  }, [searchParams.get("id")])






  return (
    <Box >
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', p: 2 }}>
        <Typography variant="h5" >{isUpdate ? "Update" : "Add"} Blog</Typography>
        <Box sx={{ marginTop: 1, marginBottom: 2 }} >
          <TextField value={heading} onChange={(event) => setHeading(event.target.value)} label="Heading" fullWidth />
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 2 }} >
          <TextField value={message} onChange={(event) => setMessage(event.target.value)} label="Message" fullWidth />
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 2 }} >
          <div>
            {
              image && image.includes("base64") ?
                <img src={image} style={{ height: 100, width: 100 }} alt="" />
                :
                <img src={generateFilePath(image)} style={{ height: 100, width: 100 }} alt="" />
            }
          </div>
          <FileUpload acceptImage={true} onFileChange={(value) => setImage(value)} />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleSubmit} variant='contained' >Save</Button>
        </Box>
      </Paper>
    </Box>
  );
}

