export const ROLES = {
  ADMIN: "ADMIN",
  SUBADMIN: "SUBADMIN",
  USER: "USER",
};


export const Order_Status_Arr = [
  {
    label: "ORDERED",
    value: "ORDERED"
  },
  {
    label: "DISPATCHED",
    value: "DISPATCHED"
  },
  {
    label: "DELIVERED",
    value: "DELIVERED"
  }
]


export const REQUEST_TYPE = {
  SELL: 'SELL',
  BUY: 'BUY',
} 

