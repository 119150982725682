import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/request'



export const getAllRequests = (obj = { pageIndex: 0, pageSize: 10 }, matchObj = {}, search = '') => {
    let query = new URLSearchParams({ ...obj, ...matchObj, search })
    return axios.get(`${url}/?${query.toString()}`)
}

export const getRequestById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}
