import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/users'

export const login = (formData) => {
    return axios.post(`${url}/web-login`, formData)
}

export const addUser = (formData) => {
    return axios.post(`${url}/addUser`, formData)
}

export const getAllUsers = () => {
    return axios.get(`${url}/getAllUsers`)
}

export const deleteUserById = (id) => {
    return axios.delete(`${url}/deleteUserById/${id}`)
}

export const approveUserById = (id) => {
    return axios.patch(`${url}/approveUserById/${id}`)
}