import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addmarquee, getmarquee } from "../../../services/marquee.service";
import { getLocation } from "../../../services/location.service";
import { errorToast, successToast } from "../../../utils/toast.util";

export default function AddMarque() {

  const [name, setName] = useState("");

  const navigate = useNavigate()




  const handleSubmit = async () => {
    try {

      let obj = {
        name,
      };

      const res = await addmarquee(obj)
      if (res.data?.message)
        successToast(res.data?.message)

      // navigate('/admin/brand/view')

    } catch (error) {
      errorToast(error)
    }
  };



  const getPreviousMarquee = async () => {
    try {
      const { data: res } = await getmarquee();
      if (res) {
        setName(res?.data?.name)
      }
    } catch (error) {
      errorToast(error)
    }
  }

  useEffect(() => {
    getPreviousMarquee()
  }, [])






  return (
    <Box >
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', p: 2 }}>
        <Typography variant="h5" >Marquee</Typography>
        <Box sx={{ marginTop: 1, marginBottom: 2 }} >
          <TextField value={name} onChange={(event) => setName(event.target.value)} label="Marquee Text" fullWidth />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleSubmit} variant='contained' >Save</Button>
        </Box>
      </Paper>
    </Box>
  );
}

