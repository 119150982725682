import axios from "axios";
import { getJwt } from "../utils/jwt.util";
import { store } from "../redux/Store";
import { resetAuth } from "../redux/features/user/authSlice";

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
    config => {
        const token = getJwt();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error)
    });
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // trigger logout  or refresh token
            // console.error("LOGOUT", error.response)
            store.dispatch(resetAuth())
        }
        return Promise.reject(error)
    }
)
export default axiosInstance
