import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/proposal'


export const getAllProposal = () => {
    return axios.get(`${url}/`)
}


export const deleteProposalById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}


export const addProposal = (formData) => {
    return axios.post(`${url}/`, formData);
};



export const getProposalById = (id) => {
    return axios.get(`${url}/getProposalById/${id}`);
};
export const getProposalByReqId = (id) => {
    return axios.get(`${url}/getProposalByReqId/${id}`);
};