import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDecodedJwt, removeJwt } from '../utils/jwt.util';
import { useDispatch } from 'react-redux';
import { resetAuth } from '../redux/features/user/authSlice';

const lightColor = 'rgba(255, 255, 255, 0.7)';

function Header(props) {
  const [userObj, setUserObj] = React.useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch()
  React.useEffect(() => {
    let obj = getDecodedJwt()
    setUserObj(obj)

  }, [])

  const logout = () => {
    removeJwt()
    dispatch(resetAuth())


  }

  return (
    <React.Fragment>
      {/* <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar> */}
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0, paddingTop: 4 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {userObj?.role}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}

            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={() => logout()}>
                <LogoutIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>

          <Box>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Users
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/user/add') }}>Add User</MenuItem>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/user/view') }}>View Users</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </Box>

          <Box>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Categories
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/category/add') }}>Add Category</MenuItem>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/category/view') }}>View Categories</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </Box>

          <Box>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Products
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/product/add') }}>Add Product</MenuItem>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/product/view') }}>View Products</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </Box>
          <Box>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Locations
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/location/add') }}>Add Location</MenuItem>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/location/view') }}>View Locations</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </Box>
          <Box>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Brands
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/brand/add') }}>Add Brand</MenuItem>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/brand/view') }}>View Brands</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </Box>


          <Box>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Blogs
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/blog/add') }}>Add Blog</MenuItem>
                    <MenuItem onClick={() => { popupState.close(); navigate('/admin/blog/view') }}>View Blogs</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </Box>


          <Box>
            <Button variant="contained" onClick={() => { navigate('/admin/marque/add') }}>
              Manage Marquee
            </Button>
          </Box>
          <Box>
            <Button variant="contained" onClick={() => { navigate('/admin/request') }}>
              Order Requests
            </Button>
          </Box>

          <Box>
            <Button variant="contained" onClick={() => { navigate('/admin/purchaseOrder') }}>
              Purchase Order
            </Button>
          </Box>

          <Box>
            <Button variant="contained" onClick={() => { navigate('/admin/payment') }}>
              Payment
            </Button>
          </Box>

          <Box>
            <Button variant="contained" onClick={() => { navigate('/admin/support') }}>
              Support
            </Button>
          </Box>

        </Box>
      </AppBar>
    </React.Fragment >
  );
}

// Header.propTypes = {
//   onDrawerToggle: PropTypes.func.isRequired,
// };

export default Header;
