import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/blog'


export const getBlogs = (obj = { pageIndex: 0, pageSize: 1000 }, search = '') => {
    return axios.get(`${url}/`)
}

export const deleteBlogById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateBlogById = (formData, id) => {
    return axios.patch(`${url}/updateById/${id}`, formData);
};


export const addBlogs = (formData) => {
    return axios.post(`${url}/`, formData);
};

export const getById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}