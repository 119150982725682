import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Refresh, Search } from '@mui/icons-material'
import { AppBar, Box, Button, CircularProgress, Grid, IconButton, LinearProgress, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { useMemo } from 'react'

export default function CustomTable({ columns, data, onChangePagination, pagination, totalCount, loading, reload, label, serverPagination, searchStr, setSearchStr, labelExtraComponent: LabelExtraComponent, searchExtraComponent: SearchExtraComponent }) {


  const final_columns = useMemo(() => columns, [columns])
  const final_data = useMemo(() => data, [data])

  const isReloadAvailable = useMemo(() => reload && typeof reload == 'function', [reload])


  let reactTableObj = useMemo(() => {
    let finalObj = {
      data: final_data?.length ? final_data : [],
      columns: final_columns,
      getCoreRowModel: getCoreRowModel(),
    }

    if (serverPagination && typeof totalCount == 'number' && pagination && onChangePagination) { // server pagination
      finalObj = {
        ...finalObj,
        pageCount: Math.ceil(totalCount / pagination?.pageSize),
        state: {
          pagination,
        },
        onPaginationChange: onChangePagination,
        manualPagination: true,
      }

    }
    else { // client auto pagination
      finalObj = {
        ...finalObj,
        getPaginationRowModel: getPaginationRowModel(),
      }
    }


    return (finalObj)



  }, [final_data, final_columns, serverPagination, totalCount, pagination, onChangePagination])



  const table = useReactTable(reactTableObj)

  const handleReload = () => {
    if (isReloadAvailable) {
      reload()
    }
  }




  return (
    <Box >

        {/* {
          loading &&
          <LinearProgress />
        } */}

        <Box sx={{ p: 2 }}>


          {
            label &&
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>

              <Typography variant='h5' sx={{ m: 1, }}>{label}</Typography>

              {
                loading ?
                  <Tooltip title="Loading" >
                    <IconButton>
                      <CircularProgress size={25} />
                    </IconButton>
                  </Tooltip>
                  :
                  reload &&
                  <Tooltip title="Reload" onClick={() => reload()} >
                    <IconButton>
                      <Refresh color="inherit" />
                    </IconButton>
                  </Tooltip>
              }

              {LabelExtraComponent}

            </Box>
          }
          {
            setSearchStr && typeof searchStr == 'string' &&
            <AppBar sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'white' }} position="static" color="default" elevation={0}>

              <Toolbar>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                  <Box>

                    {
                      setSearchStr && typeof searchStr == 'string' &&
                      <Search color="inherit" />
                    }
                  </Box>
                  <Box sx={{ flex: 1, ml: 2, mr: 2 }}>

                    <TextField
                      fullWidth
                      placeholder="Search"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      variant='standard'
                      value={searchStr}
                      onChange={(e) => {
                        setSearchStr(e.target.value);
                      }}
                    />
                  </Box>
                  <Box>
                    {SearchExtraComponent}
                  </Box>

                </Box>

              </Toolbar>
            </AppBar>
          }
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <TableCell key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {table.getRowModel().rows.length == 0 && !loading
            &&

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              pt: 4
            }}>
              <Typography>
                No Records Found
              </Typography>
            </Box>


          }
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', p: 2 }}>
            <Box>
              <Typography variant='body2'>
                Rows Per Page
              </Typography>
            </Box>
            <Box>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button sx={{
                      px: 2, py: 1, display: 'flex', flexDirection: 'row', borderRadius: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.12)',
                      }
                    }}
                      {...bindTrigger(popupState)}>
                      <Typography>
                        {
                          table.getState().pagination.pageSize
                        }
                      </Typography>
                      <KeyboardArrowDown fontSize='15' />
                    </Button>
                    <Menu {...bindMenu(popupState)}>

                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <MenuItem key={pageSize} value={pageSize} onClick={(e) => { popupState.close(e); table.setPageSize(pageSize) }} >
                          Show {pageSize}
                        </MenuItem>
                      ))}
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Box>
            <Box>
              <IconButton

                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <KeyboardDoubleArrowLeft />
              </IconButton>
              <IconButton

                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <KeyboardArrowLeft />
              </IconButton>
            </Box>
            <Box>
              <Typography variant="body2">
                {(table.getState().pagination?.pageIndex * table.getState().pagination?.pageSize) + 1}-{table.getState().pagination?.pageIndex * table.getState().pagination?.pageSize + table.getRowModel().rows.length > totalCount ? totalCount : table.getState().pagination?.pageIndex * table.getState().pagination?.pageSize + table.getRowModel().rows.length} of {totalCount}
              </Typography>
            </Box>
            <Box>
              <IconButton

                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <KeyboardArrowRight />
              </IconButton>
              <IconButton

                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <KeyboardDoubleArrowRight />
              </IconButton>
            </Box>

          </Box>
          {/* <div className="flex items-center gap-2">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <strong>
              {(table.getState().pagination?.pageIndex * table.getState().pagination?.pageSize) + 1}-{table.getState().pagination?.pageIndex * table.getState().pagination?.pageSize + table.getRowModel().rows.length} of {pagecount}
            </strong>
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[2, 5, 10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}
          {/* <div>{table.getRowModel().rows.length} Rows</div> */}
          {/* <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}

        </Box>
        {/* {
          loading &&
          <LinearProgress />
        } */}
    </Box>
  )
}