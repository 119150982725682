// import React, { useEffect, useState } from "react";
// import SelectNestedCategory from "../../../components/SelectNestedCategory";
// import { addCategory } from "../../../services/category.service";
// import { errorToast, successToast } from "../../../utils/toast.util";
// import { Box, Button, Paper, TextField } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// function AddCategory({ makeChange = false }) {

//   const [name, setName] = useState("");
//   const [parentCategoryId, setParentCategoryId] = useState("");
//   const [addSubCategory, setaddSubCategory] = useState(false);

//   const navigate = useNavigate()

//   const handleAddCategory = async () => {
//     try {

//       let obj = {
//         name,
//         parentCategoryId: parentCategoryId,
//       };

//       const res = await addCategory(obj)
//       if (res.data?.message)
//         successToast(res.data?.message)
//       navigate('/admin/category/view')

//     } catch (error) {
//       errorToast(error)
//     }
//   };

//   useEffect(() => {
//     console.log(name)
//   }, [name])




//   return (
//     <Box >
//       <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', p: 2 }}>
//         <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
//           <TextField value={name} onChange={(event) => setName(event.target.value)} label="Name" fullWidth />
//         </div>
//         <div className={makeChange ? "col-12 col-md-4 d-flex align-items-end" : "col-12"}>
//           <div className="form-check form-check-inline d-flex align-items-center pointer">
//             <input
//               className="form-check-input"
//               type="checkbox"
//               name="category-status"
//               value="option1"
//               id="add-as-sub-category"
//               checked={addSubCategory}
//               onChange={(e) => {
//                 if (e.target.checked) {
//                   setaddSubCategory(true);
//                 } else {
//                   setaddSubCategory(false);
//                 }
//               }}
//             />
//             <label className="form-check-label fs-14 pointer" htmlFor="add-as-sub-category">
//               Add as Sub Category
//             </label>
//           </div>
//         </div>
//         {addSubCategory && <SelectNestedCategory onChange={(val, level) => { setParentCategoryId(val); setParentCategoryLevel(level) }} maxDepth={3} parentCategoryIdArr={prevParentCategoryArr} hideIdArr={params?.id ? [params?.id] : []} />}
//         <Box sx={{ mt: 1 }}>
//           <Button onClick={handleAddCategory} variant='contained' >Save</Button>
//         </Box>
//       </Paper>
//     </Box>
//   );
// }

// export default AddCategory;





















import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { addCategory, getCategoryById, updateCategory } from "../../../services/category.service";
import { successToast, errorToast } from "../../../utils/toast.util";
import SelectNestedCategory from "../../../components/SelectNestedCategory";
import { generateFilePath } from "../../../services/url.service";

export default function AddCategory({ makeChange = false }) {

  const params = useParams()



  const [name, setName] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState("");
  const [addSubCategory, setaddSubCategory] = useState(false);




  const [parentCategoryLevel, setParentCategoryLevel] = useState(0);

  const [prevParentCategoryArr, setPrevParentCategoryArr] = useState([])

  // const [sortOrder, setSortOrder] = useState('')

  const navigate = useNavigate()

  const handleGetCategoryForUpdate = async () => {
    try {
      if (typeof params?.id != 'string')
        return;
      const res = await getCategoryById(params?.id);
      if (res.data.data) {
        if (res.data?.data?.name)
          setName(res.data?.data?.name)

        if (res.data?.data?.parentCategoryId) {
          setParentCategoryId(res.data?.data?.parentCategoryId)
          setaddSubCategory(true)
        }


        if (res.data?.data?.parentCategoryArr) {
          setPrevParentCategoryArr(res.data?.data?.parentCategoryArr)
          setParentCategoryLevel(res.data?.data?.parentCategoryArr?.length)
        }
      }

    } catch (error) {
      errorToast(error)
    }

  }
  useEffect(() => {
    if (params?.id) {
      handleGetCategoryForUpdate()
    }

  }, [params?.id])



  const handleSubmitCategory = async () => {
    try {

      let obj = {
        name,
        parentCategoryId: parentCategoryId,
        // sortOrder,
      };
      console.log(obj, "category obj");

      let id = params?.id ? params.id : ''
      if (!params?.id) {
        const res = await addCategory(obj)
        console.log("RESPONSE", res.data)
        if (res.data?.message)
          successToast(res.data?.message)
        id = res.data.data
      }
      if (params?.id) {

        const res = await updateCategory(obj, params?.id)
        console.log("RESPONSE", res.data)
        if (res.data?.message)
          successToast(res.data?.message)
      }

    } catch (error) {
      errorToast(error)
    }
  };




  return (
    <Box sx={{ px: 2, py: 1 }}>

      <Typography variant='h5'>
        Categories
      </Typography>

      <Paper sx={{ p: 1, m: 1 }}>
        <Box>
          <TextField
            value={name}
            onChange={(e) => { setName(e.target.value); }}
            label="Name"
            fullWidth
          />
        </Box>
        <Box >
          <Box sx={{ my: 1 }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="category-status"
              value="option1"
              id="add-as-sub-category"
              checked={addSubCategory}
              onChange={(e) => {
                if (e.target.checked) {
                  setaddSubCategory(true);
                } else {
                  setaddSubCategory(false);
                }
              }}
            />
            <label className="form-check-label fs-14 pointer" htmlFor="add-as-sub-category">
              Add as Sub Category
            </label>
          </Box>
        </Box>
        {addSubCategory && <SelectNestedCategory onChange={(val, level) => { setParentCategoryId(val); setParentCategoryLevel(level) }} maxDepth={3} parentCategoryIdArr={prevParentCategoryArr} hideIdArr={params?.id ? [params?.id] : []} />}


        <Box sx={{ mt: 1 }}>
          <Button onClick={handleSubmitCategory} variant='contained' >Save</Button>
        </Box>
      </Paper>
    </Box>
  );
}
