import React from 'react';
import {
    Navigate, Route, Routes
} from "react-router-dom";
import ViewPurchaseOrder from './ViewPurchaseOrder';
import ViewPurchaseOrderDetail from './ViewPurchaseOrderDetail';

export default function PurchaseOrderRouter() {
    return (
        <Routes>
            <Route path="" element={<ViewPurchaseOrder />} />
            <Route path="/purchaseOrderDetail/:id" element={<ViewPurchaseOrderDetail />} />
            <Route path="*" element={<Navigate to="../" relative="route" />} />
        </Routes>
    )
}
