import React from 'react'
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import ViewRequest from './ViewRequest';
import ViewRequestBids from './ViewRequestBids';

export default function RequestRouter() {
    return (
        <Routes>
            <Route path="" element={<ViewRequest />} />
            <Route path="/bid/:requestId" element={<ViewRequestBids />} />

            <Route path="*" element={<Navigate to="../" relative="route" />} />
        </Routes>
    )
}
