import { toast } from "react-hot-toast";

export const successToast = (str) => {
    toast.success(str);
}

export const normalToast = (str) => {
    toast(str);
}

export const errorToast = (err) => {
    console.error(err)
    if (err?.response?.data?.message)
        toast.error(err?.response?.data?.message);
    else if (err.message)
        toast.error(err.message);
    else if (typeof err == 'string')
        toast.error(err);
    else
        toast.error('Error');
}