import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
} from "react-router-dom";
import AddBlog from './AddBlog';
import ViewBlog from './ViewBlog';

export default function BlogRouter() {
    return (
        <Routes>
            <Route path="add" element={<AddBlog />} />
            <Route path="view" element={<ViewBlog />} />
            <Route path="*" element={<Navigate to="../view" relative="route" />} />
        </Routes>
    )
}
