import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { errorToast, successToast } from '../../../utils/toast.util';
import { deleteProductById, getAllProducts } from '../../../services/product.service';
import { getAllRequests, getRequestById } from '../../../services/request.service';
import { useParams } from 'react-router-dom';
import { addCommission, getBidsByRequestId } from '../../../services/bid.service';
import { format } from 'date-fns';
import { addProposal, getProposalByReqId } from '../../../services/proposal.service';
import { REQUEST_TYPE } from '../../../utils/constants';


const DisplayRow = ({ row, index, onChangeCommission, onSubmitCommission }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ maxWidth: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.bidByUserId?.name}
        </TableCell>
        <TableCell component="th" scope="row">
          ₹{row.productArr.reduce((acc, el) => acc + el.price, 0)}
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.commissionSet ? 'Yes' : 'No'}
        </TableCell> */}
        <TableCell component="th" scope="row">
          {format(new Date(row.createdAt), 'dd MMM yyyy')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Products :
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sno</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Base Price Per mt</TableCell>
                    <TableCell>Guage Price Per mt</TableCell>
                    <TableCell>Loading Price per mt</TableCell>
                    <TableCell>Other Price per mt</TableCell>
                    {/* <TableCell>Commission</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.productArr.map((productObj, i) => (
                    <TableRow key={productObj._id}>
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell>{productObj.name}</TableCell>
                      <TableCell>{productObj.quantity}</TableCell>
                      <TableCell> {productObj.price} </TableCell>
                      {/* <TableCell> {productObj.basePricePerQuantity} </TableCell> */}
                      <TableCell> {productObj.basePricePerQuantity} </TableCell>
                      <TableCell> {productObj.guagePricePerQuantity} </TableCell>
                      <TableCell> {productObj.loadingPricePerQuantity} </TableCell>
                      <TableCell> {productObj.otherPricePerQuantity} </TableCell>
                      {/* <TableCell>
                        <TextField label="Commission" disabled={row.commissionSet} type={'number'} value={productObj.commission} onChange={e => onChangeCommission(e.target.value, productObj.productId, row._id)} sx={{ my: 1 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          }}

                        /> 
                      </TableCell>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* <Button variant='contained' disabled={row.commissionSet} onClick={() => onSubmitCommission(row._id)} sx={{ mt: 2, mb: 2 }}> Send Commission To User</Button>
              {
                row.commissionSet &&
                <Box>
                  <Typography variant='caption' >Commission Already Set</Typography>
                </Box>
              } */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )

}


const DisplayRow2 = ({ row, index }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ maxWidth: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.productArr.reduce((acc, el, index) => acc + `${el.userObj.name} ${index != row.productArr.length - 1 ? "," : ""}`, "")}
        </TableCell>
        <TableCell component="th" scope="row">
          ₹{row.productArr.reduce((acc, el, index) => acc + el.userAmount, 0)}
          {/* ₹{row.productArr.reduce((acc, el, index) => acc + el.finalAmount, 1)} */}
        </TableCell>
        <TableCell component="th" scope="row">
          {format(new Date(row.createdAt), 'dd MMM yyyy')}
        </TableCell>
        <TableCell component="th" scope="row">
          ₹{row.productArr.reduce((acc, el, index) => acc + el.commission, 0)}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.accepted ? 'Yes' : 'No'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Products :
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sno</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Commission Per Quantity</TableCell>
                    {/* <TableCell>Commission</TableCell> */}
                    <TableCell>Price + commision</TableCell>

                    <TableCell>Base Price Per mt</TableCell>
                    <TableCell>Guage Price Per mt</TableCell>
                    <TableCell>Loading Price per mt</TableCell>
                    <TableCell>Other Price per mt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.productArr.map((productObj, i) => (
                    <TableRow key={productObj._id}>
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell>{productObj.name}</TableCell>
                      <TableCell>{productObj.quantity}</TableCell>
                      <TableCell>{productObj.userAmount} </TableCell>
                      <TableCell>{productObj.commissionPerQuantity} </TableCell>
                      {/* <TableCell>{productObj.commission} </TableCell> */}
                      <TableCell>{productObj.finalAmount} </TableCell>
                      <TableCell>{productObj.basePricePerQuantity} </TableCell>
                      <TableCell>{productObj.guagePricePerQuantity} </TableCell>
                      <TableCell>{productObj.loadingPricePerQuantity} </TableCell>
                      <TableCell>{productObj.otherPricePerQuantity} </TableCell>
                      {/* <TableCell>
                        <TextField label="Commission" disabled={row.commissionSet} type={'number'} value={productObj.commission} onChange={e => onChangeCommission(e.target.value, productObj.productId, row._id)} sx={{ my: 1 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          }}

                        /> 
                      </TableCell>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* <Button variant='contained' disabled={row.commissionSet} onClick={() => onSubmitCommission(row._id)} sx={{ mt: 2, mb: 2 }}> Send Commission To User</Button>
              {
                row.commissionSet &&
                <Box>
                  <Typography variant='caption' >Commission Already Set</Typography>
                </Box>
              } */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )

}


export default function ViewRequestBids() {

  const params = useParams()

  const [rows, setRows] = useState([])
  const [requestObj, setRequestObj] = useState({})
  const [proposalArr, setProposalArr] = useState([]);

  const [bids, setBids] = useState([])
  const [bidModalVisible, setBidModalVisible] = useState(false)



  useEffect(() => {
    handleGetBids()
    handleGetRequest()
    handleGetProposals()
  }, [params?.requestId])



  const handleGetProposals = async () => {
    try {
      const res = await getProposalByReqId(params?.requestId)
      if (res.data?.data) {
        console.log(res.data?.data, "Proposal")
        setProposalArr(res.data.data)
      }


    } catch (error) {
      errorToast(error)
    }
  }


  const handleGetRequest = async () => {
    try {
      const res = await getRequestById(params?.requestId)
      console.log(res.data?.data, ';asdf')
      if (res.data?.data)
        setRequestObj(res.data.data)


    } catch (error) {
      errorToast(error)
    }
  }

  const handleGetBids = async () => {
    try {
      const res = await getBidsByRequestId(params?.requestId)
      if (res.data?.data)
        setRows(res.data.data.sort((a, b) => a.commissionSet - b.commissionSet))

    } catch (error) {
      errorToast(error)
    }
  }


  const onChangeCommission = (value, index) => {
  } // const onChangeCommission = (value, index) => {
  //   setRows(prev => prev.map(el => el._id == bidId ? { ...el, productArr: el.productArr.map(elx => elx.productId == productId ? { ...elx, commission: value } : elx) } : el))
  // }

  const onSubmitCommission = async (bidId) => {
    try {
      if (window.confirm('Are you sure you want to send this commission')) {
        let obj = rows.find(el => el._id == bidId)
        const res = await addCommission(obj, bidId)
        if (res.data?.message)
          successToast(res.data.message)


      }
    } catch (error) {
      errorToast(error)
    }
  }



  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden', p: 2 }} >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ m: 1 }}>Request: {requestObj?.id}</Typography>

          {proposalArr?.some(el => el.accepted) ?
            <Button variant='contained' disabled>Proposal Accepted</Button>
            :

            <AddProposal bidArr={rows} requestObj={requestObj} params={params} handleGetProposals={handleGetProposals} />
          }

        </Box>
        <Typography variant='h6' sx={{ m: 1 }}>Request Made By: {requestObj?.userId?.name}</Typography>
        <Typography variant='body1' sx={{ m: 1 }}>Request Type: {requestObj?.requestType}</Typography>
        <Typography variant='body1' sx={{ m: 1 }}>{requestObj?.description}</Typography>
        <Typography variant='body1' sx={{ m: 1 }}> Prefferd Brand: {requestObj?.brand}</Typography>
        <Typography variant='body1' sx={{ m: 1 }}> Prefferd Location: {requestObj?.location}</Typography>
        {/* {
          requestObj?.requestType == REQUEST_TYPE.SELL &&
          <Typography variant='body1' sx={{ m: 1 }}> Prefferd Price: {requestObj?.price}</Typography>
        } */}
        <Typography variant='h6' sx={{ m: 1 }}>Products:</Typography>
        {requestObj?.productArr?.map((el, i) => {
          return (
            <Typography variant='body2' key={i} sx={{ m: 1 }}>{i + 1}. {el.name} x {el.quantity}, preffered price : {el.prefferedPrice}</Typography>
          )
        })}

        <Typography variant='h6' sx={{ m: 1, mt: 3 }}>Bids Placed:</Typography>
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ maxWidth: 50 }}></TableCell>
                  <TableCell>Sno</TableCell>
                  <TableCell>Bid Placed By</TableCell>
                  <TableCell>Total</TableCell>
                  {/* <TableCell>Bid Sent To User</TableCell> */}
                  <TableCell>Bid Placed On</TableCell>
                  {/* <TableCell >Delete Product</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <DisplayRow key={row._id} row={row} index={i} onChangeCommission={onChangeCommission} onSubmitCommission={onSubmitCommission} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>


        </Box>


        <Typography variant='h6' sx={{ m: 1, mt: 3 }}>Proposals :</Typography>
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ maxWidth: 50 }}></TableCell>
                  <TableCell>Sno</TableCell>
                  <TableCell>Bid Placed By</TableCell>
                  <TableCell>Bid</TableCell>
                  <TableCell>Bid Placed On</TableCell>
                  <TableCell>Total commission</TableCell>
                  <TableCell>Accepted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalArr.map((row, i) => (
                  <DisplayRow2 key={row._id} row={row} index={i} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>


        </Box>
      </Paper>
    </Box>
  )
}


function AddProposal({ bidArr, requestObj, params, handleGetProposals }) {
  const [showDialog, setShowDialog] = useState(false)
  const [productArr, setProductArr] = useState([])


  const [bidsArr, setBidsArr] = useState([]);
  const handleSubmit = async () => {
    try {

      if (!requestObj._id) {
        errorToast("Invalid Request");
        return;
      }
      if (productArr.some(el => el.commission == 0 || el.commission < 0)) {
        errorToast("Commission cannot be 0 or less than 0");
        return;
      }
      if (productArr.some(el => !el.selectedBid)) {
        errorToast("Please select bids for each product !");
        return;
      }

      let obj = {
        requestId: requestObj?._id,
        requestDescription: requestObj?.description,
        requestType: requestObj?.requestType,
        productArr: productArr.map(el => {
          let bidProductObj = el?.selectedBid?.productArr?.find(elx => elx.productId == el.productId._id)
          if (!bidProductObj) {
            throw new Error('Please select bid for all products')
          }
          let price = bidProductObj?.price
          let obj = {
            productId: el.productId._id,
            name: el.name,
            quantity: el.quantity,
            commission: parseFloat(el.commission),
            commissionPerQuantity: parseFloat(el.commissionPerQuantity),
            basePricePerQuantity: bidProductObj.basePricePerQuantity,
            loadingPricePerQuantity: bidProductObj.loadingPricePerQuantity,
            guagePricePerQuantity: bidProductObj.guagePricePerQuantity,
            otherPricePerQuantity: bidProductObj.otherPricePerQuantity,
            bidId: el?.selectedBid?._id,
            userId: el?.selectedBid?.bidByUserId?._id,
            userAmount:  price,
            finalAmount: requestObj?.requestType == REQUEST_TYPE.SELL ? (parseFloat(price) - parseFloat(el.commission)): (parseFloat(price) + parseFloat(el.commission))
          }
          return obj
        })


      }
      console.log(obj, "obj")
      let { data: res } = await addProposal(obj)
      if (res.message) {
        setShowDialog(false);
        successToast(res.message)
        handleGetProposals()
      }

    } catch (error) {
      errorToast(error)
    }
  }

  useEffect(() => {
    if (requestObj?.productArr?.length) {
      console.log("WTF", requestObj?.productArr)
      setProductArr(requestObj?.productArr?.map(el => ({ ...el, commission: 0, selectedBid: "", bidsArr: bidArr.filter(elx => elx.productArr.some(elm => elm.productId == el?.productId?._id)) })))
    }

  }, [requestObj?.productArr, bidArr])


  useEffect(() => {
    // if (requestObj?.productArr?.length) {
    //   setProductArr(requestObj?.productArr?.map(el => ({ ...el, commission: 0 })))
    // }

  }, [bidArr])



  const handleSelectBid = (index, value, productId) => {
    let tempArr = productArr;
    let tempSelectedBid = bidArr.find(el => el._id == value)
    tempArr[index].selectedBid = tempSelectedBid

    setProductArr([...tempArr])
  }

  const onChangeCommissionPerQuantity = (index, value) => {
    let tempArr = productArr;


    tempArr[index].commissionPerQuantity = value
    tempArr[index].commission = value * tempArr[index]?.quantity


    setProductArr([...tempArr])
  }

  const onChangeCommission = (index, value) => {
    let tempArr = productArr;


    tempArr[index].commission = value

    setProductArr([...tempArr])
  }



  return (
    <>
      <Button variant='contained' onClick={() => setShowDialog(true)} disabled={!bidArr?.length}>Create Proposal</Button>
      <Dialog
        fullWidth={'lg'}
        maxWidth={'lg'}
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogContent>

          <Typography variant='h6'>
            Add Proposal for {requestObj?.id}
          </Typography>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit}
          >

            {/* <Table sx={{ minWidth: 650 }} aria-label="simple table">

            </Table> */}
            {productArr?.map((el, index) => {

              const bidProductObj = el.selectedBid?.productArr?.find(elx => elx.productId == el?.productId?._id)
              const finalPricePerQuantityWithoutCommision = bidProductObj?.price

              return (
                <>
                  <Box key={index} sx={{ display: 'flex', flexDirection: 'row', mt: 3, alignItems: 'center' }}>
                    <Typography>
                      {el.name}
                    </Typography>
                    <FormControl sx={{ minWidth: 120, ml: 3, mr: 3 }}>
                      <InputLabel htmlFor="max-width">Bid </InputLabel>
                      <Select
                        label="Bid"
                        onChange={(e) => handleSelectBid(index, e.target.value, el.productId)}
                      >
                        {el?.bidsArr.map((ele, i) => (
                          <MenuItem value={ele._id}>{ele?.bidByUserId?.name}  <Typography variant='caption' sx={{ ml: 1 }} >(₹{ele?.productArr.find(elx => el.productId?._id == elx.productId)?.price})</Typography> </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField onChange={(e) => onChangeCommissionPerQuantity(index, e.target.value)} value={el.commissionPerQuantity} label="Commission Per mt" type={'number'}
                      sx={{ my: 1 }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                      }}
                    />

                    <Box sx={{ ml: 2, mr: 2 }}>
                      x
                    </Box>

                    <Box sx={{ mr: 2 }}>
                      {el.quantity}
                    </Box>

                    {/* <TextField value={el.commission} label="Total Commission" type={'number'}
                  sx={{ my: 1 }}
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  }}
                /> */}


                    {
                      requestObj?.requestType == REQUEST_TYPE.SELL &&
                      <TextField
                        value={el.selectedBid ? (finalPricePerQuantityWithoutCommision - parseFloat(el.commissionPerQuantity)) : el.commissionPerQuantity}
                        label="Final Price for seller (per mt)"
                        type={'number'}
                        sx={{ my: 1 }}
                        disabled
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }}
                      />
                    }

                  </Box>
                </>
              )
            }

            )}


          </Box>
        </DialogContent>
        <DialogActions>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button onClick={() => handleSubmit()} variant='contained' sx={{ mr: 1 }}  >Submit</Button>
            <Button onClick={() => setShowDialog(false)} variant='outlined' color='error' >Close</Button>
          </Box>
        </DialogActions>
      </Dialog>

    </>
  )
}


