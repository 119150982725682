import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/product'


export const addProduct = (formData) => {
    return axios.post(`${url}/`, formData)
}

export const getAllProducts = () => {
    return axios.get(`${url}/`)
}

export const deleteProductById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateProductById = (formData, id) => {
    return axios.patch(`${url}/updateById/${id}`, formData);
};

export const getById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}