import axios from './axios.service';
import BaseUrl from './url.service';

const url = BaseUrl + '/category'

export const addCategory = (formData) => {
  return axios.post(url + "/addCategory", formData);
};

export const getCategoryById = (id) => {
  return axios.get(`${url}/getCategoryById/${id}`)
};
export const getCategory = (obj = { pageIndex: 0, pageSize: 10 }, search = '') => {
  let query = new URLSearchParams({ ...obj, ...search })
  return axios.get(`${url}/getCategory/?${query.toString()}`)
  // return axios.get(`${url}/getCategory?${query}`);
};

export const deleteCategory = (id) => {
  return axios.delete(`${url}/deleteById/${id}`);
};

export const updateCategory = (formData, id) => {
  return axios.patch(`${url}/updateById/${id}`, formData);
};

export const getNestedCategories = () => {
  return axios.get(`${url}/getNestedCategories`);
};