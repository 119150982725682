import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { errorToast, successToast } from '../../../utils/toast.util';
import { approveUserById, deleteUserById, getAllUsers } from '../../../services/user.service';
import { generateFilePath } from '../../../services/url.service';
import { useQuery } from '@tanstack/react-query';
import CustomTable from '../../../components/CustomTable';

export default function ViewUser() {

  // const [rows, setRows] = useState([])

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const { isLoading, isFetching, error, data, refetch } = useQuery({
    queryKey: ['users', pagination],
    queryFn: () => getAllUsers(pagination).then(res => res?.data),
    onError: err => errorToast(err),
    keepPreviousData: true
  })


  const loading = isLoading || isFetching;

  // useEffect(() => {
  //   handleGet()
  // }, [])


  // const handleGet = async () => {
  //   try {
  //     const res = await getAllUsers()
  //     if (res.data?.data)
  //       setRows(res.data.data)

  //   } catch (error) {
  //     errorToast(error)
  //   }
  // }

  const approveUser = async (id) => {
    try {
      const res = await approveUserById(id);
      if (res.data?.message)
        successToast(res.data?.message)

      refetch()
      // handleGet()

    } catch (error) {
      errorToast(error)
    }
  }



  const deleteUser = async (id) => {
    try {
      const res = await deleteUserById(id);
      if (res.data?.message)
        successToast(res.data?.message)

      refetch()
      // handleGet()

    } catch (error) {
      errorToast(error)
    }
  }

  const columns = useMemo(() => [
    {
      header: "Name",
      accessorFn: row => row?.name,
      // cell: info => info.getValue(),
    },
    {
      header: "Firm Name",
      accessorFn: row => row?.firmName,
      // cell: info => info.getValue(),
    },
    {
      header: "Contact",
      accessorFn: row => row?.phone,
      // cell: info => info.getValue(),
    },
    {
      header: "Approval Status",
      accessorFn: row => row.approved ? 'Approved' : 'pending',
      // cell: info => info.getValue(),
    },
    {
      header: "Email",
      accessorFn: row => row?.email,
      // cell: info => info.getValue(),
    },
    {
      header: "Address",
      accessorFn: row => row?.address,
      // cell: info => info.getValue(),
    },
    // {
    //   header: 'View GST Verification Details (API)',
    //   accessorFn: row => row?.gstObj,
    //   cell: info => info ? <pre>{JSON.stringify(info.getValue(), null, 2)}</pre> : ''
    // },
    {
      header: "View Kyc Details",
      accessorFn: row => row?.documents,
      cell: info => info.getValue()?.length ? <a href={generateFilePath(info.getValue()[0].fileName)} target="_blank" rel="noopener noreferrer">View Kyc Docs</a> : '',
    },
    {
      header: "Approve",
      cell: info => <Button variant='outlined' disabled={info.row?.original?.approved} onClick={() => approveUser(info.row?.original?._id)}>Approve</Button>,
    },
    {
      header: "Delete",
      cell: info => <Button variant='outlined' color='error' onClick={() => deleteUser(info.row?.original?._id)}>Delete</Button>,
    },
  ], [])



  return (
    <Box>
      <Paper sx={{ maxWidth: "max(936px, 80vw)", margin: 'auto', overflow: 'hidden', p: 2 }} >

        <CustomTable
          label={'View Users'}
          columns={columns}
          data={data?.data}
          totalCount={data?.data?.length}
          // totalCount={data?.count}
          loading={loading}
          // onChangePagination={setPagination}
          // pagination={pagination}
          reload={refetch}
        // serverPagination
        // labelExtraComponent={
        //   <>
        //     <Button>Dowload</Button>
        //   </>
        // }
        // searchExtraComponent={
        //   <>
        //     asdfas
        //     <IconButton>
        //       <Download></Download>
        //     </IconButton>
        //   </>
        // }
        // searchStr={searchStr}
        // setSearchStr={setSearchStr}
        />
      </Paper>
    </Box>
  )
}
